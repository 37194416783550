// TODO: in futuro usare site settimngs su plone per gestire il logo
import LogoEmilia from '~/components/Layout/logoEmilia.png';
import React from 'react';
import { Helmet } from '@plone/volto/helpers';
import { getSiteProperty } from '@italia/helpers';
import { useIntl } from 'react-intl';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const Logo = () => {
  const intl = useIntl();
  const schemaorg = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: "Camera di Commercio dell'Emilia",
    url: 'https://www.emilia.camcom.it/',
  };
  return (
    <>
      <img
        src={LogoEmilia}
        width="279"
        height="82"
        className="icon logo-emilia"
        alt={getSiteProperty('siteTitle', intl.locale)}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaorg)}</script>
      </Helmet>
    </>
  );
};
export default Logo;
