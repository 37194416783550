import LogoEmilia from '~/components/Layout/logoFooterEmiliaWhite.png';
import React from 'react';
import { getSiteProperty } from '@italia/helpers';
import { useIntl } from 'react-intl';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */
const LogoFooter = () => {
  const intl = useIntl();
  return (
    <img
      src={LogoEmilia}
      width="279"
      height="82"
      className="icon logo-emilia"
      alt={getSiteProperty('siteTitle', intl.locale)}
    />
  );
};

export default LogoFooter;
